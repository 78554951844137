.confluence-container {
  margin-bottom: 4rem;
}
.confluence-container .confluence-search-heading {
  margin: 0 0 2rem 0;
}
.confluence-container .search-form {
  display: flex;
  justify-content: space-between;
}
.confluence-container .search-form .form-group {
  width: 100%;
  margin-right: 1rem;
}
.confluence-container .search-form .form-group input[type=text] {
  width: 100%;
}
.confluence-container .heading-row {
  display: flex;
  justify-content: space-between;
}
.confluence-container .heading-row h5 {
  margin: 2rem 0 1rem 0;
  font-weight: 700;
}
.confluence-container .confluence-customer {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
}
.confluence-container .confluence-customer span, .confluence-container .confluence-customer a {
  align-self: center;
}
.confluence-container .fa-external-link-square {
  margin-left: 1rem;
  font-size: 14px;
}