.main-home-grid .panel-heading {
  padding-bottom: 16px;
}
.main-home-grid .panel-body p {
  margin: 0;
}
.main-home-grid .panel-footer .fa {
  margin-right: 0px;
}

.login-page {
  background: url(https://aadcdn.msftauthimages.net/dbd5a2dd-s7qfevvwq6wm3d0-gkz4k8r0vbfojmjmew-zkzsxqcu/logintenantbranding/0/illustration?ts=637054733082344020) no-repeat 50% 50%/cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.55);
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-page .login-container {
  min-width: 440px;
  min-height: 368px;
  padding: 44px;
  background-color: white;
}
.login-page .login-container h4 {
  color: #1B1B1B;
  font-weight: 500;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.login-page .login-container .banner-logo {
  height: 36px;
  width: 94.05px;
  margin: 0;
}
.login-page .login-container input {
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  min-height: 36px;
  color: black;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-color: rgba(0, 0, 0, 0.6);
  border-bottom-width: 1px;
  margin-bottom: 1.5rem;
}
.login-page .login-container input:focus {
  background-color: rgba(0, 0, 0, 0);
  border-bottom-color: #0067B8;
  outline: none;
}
.login-page .login-container .btn-container {
  display: flex;
  flex-direction: row-reverse;
}
.login-page .login-container .btn-container .btn-primary {
  margin-top: 4rem;
  background-color: #0067B8;
  border-radius: 0;
  padding-left: 4rem;
  padding-right: 4rem;
}
.login-page .optional-login-container .azure-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  text-decoration: none;
  margin-top: 2rem;
  min-width: 440px;
  min-height: 48px;
  padding: 0px 40px 0px 40px;
}
.login-page .optional-login-container .azure-btn span {
  color: #1B1B1B;
  margin: 0 1.5rem 0 0;
  font-size: 15px;
}
.login-page .optional-login-container .azure-btn h6 {
  font-size: 15px;
  font-weight: 400;
  color: #1B1B1B;
  margin: 0;
}
.login-page .optional-login-container .azure-btn:hover {
  background-color: #e2e2e2;
}
@media only screen and (max-width: 460px) {
  .login-page .login-container {
    min-width: 100%;
    padding: 30px;
  }
  .login-page .optional-login-container {
    min-width: 100%;
    min-height: calc( 100vh - 368px);
    background-color: white;
    padding: 0px 40px 0px 40px;
  }
  .login-page .optional-login-container .azure-btn {
    border: 1px solid grey;
    min-width: 100%;
    margin-top: 0;
    padding: 0px 20px 0px 20px;
  }
}

header {
  margin-bottom: 4rem;
}
header .dropdown-menu span {
  margin-left: 1rem;
}

.insight-wrapper {
  margin-bottom: 4rem;
}

.push-panel-down {
  margin-top: 4rem;
}
.push-panel-down a, .push-panel-down span {
  margin-right: 0.5rem;
}

:root[theme=dark] .flatpickr-days {
  background-color: #444C55 !important;
}
:root[theme=dark] .flatpickr-days .dayContainer .flatpickr-day {
  color: #ffffff;
}
:root[theme=dark] .flatpickr-days .dayContainer .flatpickr-day.disabled, :root[theme=dark] .flatpickr-days .dayContainer .flatpickr-day.prevMonthDay, :root[theme=dark] .flatpickr-days .dayContainer .flatpickr-day.nextMonthDay {
  color: #989DA2;
}
:root[theme=dark] .date-from-filter .flatpickr-input, :root[theme=dark] .date-to-filter .flatpickr-input {
  background-color: #313639;
  color: #ffffff;
}
:root[theme=dark] #customer-filter, :root[theme=dark] #platforms-filter, :root[theme=dark] #servicegroup-filter, :root[theme=dark] #ci-filter {
  background-color: #1E2022;
}
:root[theme=dark] .custom-info-panel {
  border: 1px solid #444c55;
}
:root[theme=dark] .nav-tabs {
  border-bottom: 1px solid #444c55 !important;
}
:root[theme=dark] .kpis .body .item.NO-DATA {
  background-color: #606f7d;
}
:root[theme=dark] .info-tag {
  color: #b3b7c3;
}
:root[theme=dark] .navigation-panel ul .platform-li {
  border-bottom: 1px solid #444C55;
}
:root[theme=dark] .navigation-panel ul .platform-li.active {
  background-color: #313639;
}
:root[theme=dark] .navigation-panel ul .platform-li .platform-menu-text-item:hover {
  cursor: pointer;
  background-color: #313639;
}
:root[theme=dark] .navigation-panel ul .service-group-li.active {
  background-color: #313639;
}
:root[theme=dark] .navigation-panel ul .ci-li a {
  color: #ffff;
}
:root[theme=dark] .navigation-panel ul .ci-li.active {
  background: #606a70;
}
:root[theme=dark] .navigation-panel ul .ci-li:hover {
  background: #495054;
}
:root[theme=dark] .navigation-panel ul .ci-li:hover.active {
  background: #545d62;
}
:root[theme=dark] .report-customer-selection .customer-list .customer:hover {
  background-color: #313639;
}
:root[theme=dark] .report-customer-selection .customer-list a {
  color: #ffff;
}
:root[theme=dark] .report-customer-selection .customer-list i {
  color: #0FC8F2;
}

:root[theme=light] .custom-info-panel {
  border: 1px solid #e0e3ee;
}
:root[theme=light] .nav-tabs {
  border-bottom: 1px solid #d2d6e1 !important;
}
:root[theme=light] .kpis .body .item.NO-DATA {
  background-color: #d2d6e1;
}
:root[theme=light] .info-tag {
  color: #606f7d;
}
:root[theme=light] .navigation-panel ul .platform-li {
  border-bottom: 1px solid #e0e3ee;
}
:root[theme=light] .navigation-panel ul .platform-li.active {
  background-color: #e0e3ee;
}
:root[theme=light] .navigation-panel ul .platform-li .platform-menu-text-item:hover {
  cursor: pointer;
  background-color: #e0e3ee;
}
:root[theme=light] .navigation-panel ul .service-group-li.active {
  background-color: #e0e3ee;
}
:root[theme=light] .navigation-panel ul .ci-li a {
  color: #455565;
}
:root[theme=light] .navigation-panel ul .ci-li.active {
  background: #afb6d3;
}
:root[theme=light] .navigation-panel ul .ci-li:hover {
  background: #bfc5dc;
}
:root[theme=light] .navigation-panel ul .ci-li:hover.active {
  background: #afb6d3;
}
:root[theme=light] .report-customer-selection .customer-list .customer:hover {
  background-color: #e0e3ee;
}
:root[theme=light] .report-customer-selection .customer-list a {
  color: #455565;
}
:root[theme=light] .report-customer-selection .customer-list i {
  color: #1872BC;
}
:root[theme=light] #filters-box #customer-list li label:hover, :root[theme=light] #filters-box #servicegroup-list li label:hover, :root[theme=light] #filters-box #platforms-list li label:hover, :root[theme=light] #filters-box #ci-list li label:hover {
  color: white;
}
:root[theme=light] .zerolinelayer .yzl {
  stroke: #b3b7c3 !important;
}