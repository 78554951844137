.advanced-search-container {
  margin-bottom: 10rem;
}
.advanced-search-container #filters-box input[type=radio] {
  display: none;
}
.advanced-search-container #filters-box .control-label {
  display: flex;
  justify-content: space-between;
}
.advanced-search-container #filters-box button {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@supports (-moz-appearance: none) {
  .advanced-search-container #filters-box button {
    white-space: inherit;
  }
}
.advanced-search-container #filters-box #customer-filter, .advanced-search-container #filters-box #platforms-filter, .advanced-search-container #filters-box #servicegroup-filter, .advanced-search-container #filters-box #ci-filter {
  border: 0;
  border-radius: 0;
  padding: 8px 19px;
}
.advanced-search-container #filters-box .search-hr {
  margin: 0 19px 16px 19px;
}
.advanced-search-container #filters-box ul li:hover {
  background-color: #0FC8F2;
}
.advanced-search-container #filters-box ul li label {
  padding: 8px 20px;
}
.advanced-search-container #filters-box .cis input[type=checkbox] {
  width: 15px;
  height: 15px;
  margin: 1rem 1rem 0 2rem;
}
.advanced-search-container .advanced-search-results-container .messages-margin-bottom {
  margin: 0 0 2rem 0;
}
.advanced-search-container .advanced-search-results-container .applied-filters-container {
  margin-bottom: 2rem;
}
.advanced-search-container .advanced-search-results-container .fa-info-circle {
  margin: 0rem 0.5rem 2rem 0rem;
}
.advanced-search-container .advanced-search-results-container #pagin-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 2rem;
}
.advanced-search-container .advanced-search-results-container #pagin-controls .pagin-info {
  color: #606f7d;
}
.advanced-search-container .advanced-search-results-container #pagin-controls p {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 0.1rem;
}
.advanced-search-container .advanced-search-results-container #pagin-controls span {
  font-size: 22px;
  color: #0FC8F2;
}
.advanced-search-container .advanced-search-results-container #pagin-controls span:hover {
  cursor: pointer;
}

#caas-execution .panel-group {
  margin-bottom: 4rem;
}
#caas-execution .panel-group .main-heading-execution {
  margin: 2rem 0;
}
#caas-execution .panel-group .execution-top-panel-content {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
#caas-execution .panel-group .execution-top-panel-content .check-type {
  margin: 0 1rem;
}
#caas-execution .panel-group .execution-info-panel {
  display: flex;
  align-items: center;
}
#caas-execution .panel-group .execution-info-panel .check-type {
  margin-right: 1rem;
}
#caas-execution .panel-group .remediation span {
  margin-left: 1rem;
}
#caas-execution .panel-group .custom-info-panel {
  padding: 3rem;
}
#caas-execution .panel-group .custom-info-panel .execution-info-output .message {
  color: #b3b7c3;
}

.risk-level-filters input[type=checkbox], .check-type-filters input[type=checkbox], .status-filters input[type=checkbox] {
  display: none;
}
.risk-level-filters .widgets, .check-type-filters .widgets, .status-filters .widgets {
  display: flex;
  height: 29px;
  align-items: center;
}
.risk-level-filters .widgets .check-type, .risk-level-filters .widgets .status, .check-type-filters .widgets .check-type, .check-type-filters .widgets .status, .status-filters .widgets .check-type, .status-filters .widgets .status {
  margin: 0 1rem 0 0;
}
.risk-level-filters .widgets .risk-level, .check-type-filters .widgets .risk-level, .status-filters .widgets .risk-level {
  margin: 0 1rem 0 0;
}
.risk-level-filters .widgets .disabled, .check-type-filters .widgets .disabled, .status-filters .widgets .disabled {
  opacity: 0.55;
}
.risk-level-filters .widgets .check-type:hover, .risk-level-filters .widgets .status:hover, .risk-level-filters .widgets .risk-level:hover, .check-type-filters .widgets .check-type:hover, .check-type-filters .widgets .status:hover, .check-type-filters .widgets .risk-level:hover, .status-filters .widgets .check-type:hover, .status-filters .widgets .status:hover, .status-filters .widgets .risk-level:hover {
  opacity: 0.8;
  cursor: pointer;
}

#tables th {
  padding: 12px;
}
#tables td {
  padding: 12px;
  vertical-align: middle;
}
#tables .center-icons {
  text-align: center;
}
#tables .center-icons .risk-level {
  margin: auto;
}
#tables .ci-report-task-td {
  min-width: 200px;
}
#tables .reduce-col-width-timestamp {
  width: 160px;
}
#tables .reduce-col-width-tag {
  width: 110px;
}
#tables .reduce-col-width-icon {
  width: 60px;
}

.warning-text {
  color: #ffbd53;
  margin-bottom: 4rem;
}

.form-warning-text {
  color: #ffbd53;
  margin-bottom: 1rem;
}

.danger-text {
  color: #ef4023;
}

.remove-margins {
  margin: 0;
}

.no-wrap {
  white-space: nowrap;
}

.info-tag {
  margin: 0;
  align-self: flex-end;
  color: #b3b7c3;
}

#filters-spinner, #history-spinner {
  margin-bottom: 2rem;
}

.fa-external-link-square {
  font-size: 20px;
}

.breadcrumbs-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1.5rem;
}
.breadcrumbs-container .breadcrumb .fa-external-link-square {
  font-size: 12px;
}
.breadcrumbs-container .breadcrumb .btn-primary {
  margin-right: 2rem;
}
.breadcrumbs-container .breadcrumb .btn-primary span {
  font-style: normal;
}
.breadcrumbs-container .breadcrumb .btn-primary .fa-arrow-left {
  font-size: 13.5px;
}

.nav-tabs li {
  width: 20rem !important;
}

.panel-heading[data-toggle=collapse]::before {
  font-size: 25px;
}

.check-type {
  text-align: center;
  padding: 4px;
  border-radius: 2px;
  width: 80px;
  height: 29px;
  white-space: nowrap;
  color: white;
}
.check-type.CIS-1 {
  background-color: #36B37EC0;
}
.check-type.CIS-2 {
  background-color: #6554C0C0;
}
.check-type.NTT {
  background-color: #0072bc;
}
.check-type.NA {
  width: 100px;
  background-color: #606f7d;
}

.status {
  text-align: center;
  padding: 5px;
  border-radius: 2px;
  width: 80px;
  height: 29px;
  white-space: nowrap;
  color: white;
}
.status.Fail {
  background-color: #ef4023;
}
.status.No-Access {
  background-color: #ffbd53;
}
.status.Pass {
  background-color: #1dbd45;
}
.status.No-Support {
  background-color: #2cd5b6;
}

.risk-level {
  height: 20px;
  width: 20px;
}
.risk-level .fa-exclamation-triangle {
  font-size: 20px;
}
.risk-level .level-1 {
  color: #ef4023;
}
.risk-level .level-2 {
  color: #ffbd53;
}
.risk-level .level-3 {
  color: #0fc8f2;
}
.risk-level .level-4 {
  color: #6554C0C0;
}

#taskoutcomecode pre {
  margin-top: 15px;
}
#taskoutcomecode .accent-heading {
  font-style: normal;
  font-family: monospace;
}
#taskoutcomecode .message {
  color: #b3b7c3;
}
#taskoutcomecode .heading {
  color: #ef4023;
}

#alerttaskaccordion {
  border: None;
}
#alerttaskaccordion .accordion-heading {
  padding-top: 0;
  padding-bottom: 0;
}

.report-customer-selection .customer-list {
  margin-bottom: 4rem;
}
.report-customer-selection .customer-list .customer {
  padding: 1.5rem 1rem;
}
.report-customer-selection .customer-list hr {
  margin: 0;
}
.report-customer-selection .customer-list a {
  text-decoration: none;
}
.report-customer-selection .customer-list i {
  color: #0FC8F2;
}

.caas-report-container {
  padding: 0 3rem 3rem 3rem;
}
.caas-report-container .report-headings-container {
  margin-bottom: 3rem;
}
.caas-report-container #spinner-ci-report, .caas-report-container #spinner-ci-content {
  margin: 0 0 4rem 4rem;
}
.caas-report-container #timestamp-dropdown {
  margin-bottom: 4rem;
}
.caas-report-container #timestamp-dropdown .dropdown-container {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.caas-report-container #timestamp-dropdown .dropdown-container .dropdown-box {
  width: 20%;
  margin-right: 2rem;
}
.caas-report-container #timestamp-dropdown .dropdown-container .btn-box {
  margin-top: auto;
}
@media only screen and (max-width: 1300px) {
  .caas-report-container #timestamp-dropdown .dropdown-container .dropdown-box {
    width: 22%;
  }
}
@media only screen and (max-width: 1200px) {
  .caas-report-container #timestamp-dropdown .dropdown-container .dropdown-box {
    width: 27%;
  }
}
@media only screen and (max-width: 1000px) {
  .caas-report-container #timestamp-dropdown .dropdown-container .dropdown-box {
    width: 35%;
  }
}
@media only screen and (max-width: 768px) {
  .caas-report-container #timestamp-dropdown .dropdown-container .dropdown-box {
    width: 42%;
  }
}
@media only screen and (max-width: 600px) {
  .caas-report-container #timestamp-dropdown .dropdown-container .dropdown-box {
    width: 100%;
  }
}
.caas-report-container #timestamp-dropdown input[type=radio] {
  display: none;
}
.caas-report-container #timestamp-dropdown .dropdown-toggle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@supports (-moz-appearance: none) {
  .caas-report-container #timestamp-dropdown button {
    white-space: inherit;
  }
}
.caas-report-container #timestamp-dropdown ul li:hover {
  background-color: #0FC8F2;
}
.caas-report-container #timestamp-dropdown ul li label {
  padding: 8px 20px;
}
.caas-report-container #timestamp-dropdown .timestamp-btn-column {
  margin-top: auto;
  margin-left: auto;
}

.navigation-panel {
  overflow-x: auto;
  height: calc( 100vh - 206px );
  overflow-wrap: anywhere;
  padding-bottom: 10px;
}
.navigation-panel .warning-text {
  padding: 1rem 0 1rem 1.5rem;
  margin: 0;
}
.navigation-panel .loader-spinner {
  display: none;
}
.navigation-panel p {
  margin: 0;
}
.navigation-panel ul {
  margin: 0;
  padding: 0;
}
.navigation-panel ul.platorm-ul {
  margin-bottom: 2rem;
  padding-right: 1rem;
}
.navigation-panel ul .platform-li {
  margin: 0;
  padding: 0;
}
.navigation-panel ul .platform-li .platform-menu-text-item {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.navigation-panel ul .platform-li .platform-menu-text-item:hover {
  cursor: pointer;
}
.navigation-panel ul .service-group-li {
  margin: 0;
  padding: 1rem;
}
.navigation-panel ul .service-group-li .menu-text-item {
  display: flex;
  justify-content: space-between;
}
.navigation-panel ul .service-group-li .inner-item {
  display: flex;
}
.navigation-panel ul .service-group-li .inner-item span {
  margin-right: 0.5rem;
  font-size: 16px;
  color: #0FC8F2;
}
.navigation-panel ul .service-group-li .menu-text-item:hover {
  cursor: pointer;
}
.navigation-panel ul .service-group-li .fa-angle-up {
  display: none;
}
.navigation-panel ul .ci-li {
  margin: 0 !important;
  padding: 0.5rem 0 0.5rem 1.5rem;
}
.navigation-panel ul .ci-li a {
  text-decoration: none;
}
.navigation-panel ul .ci-li:first-child {
  margin-top: 1rem !important;
}

.ci-dashboard {
  margin: 0;
}
.ci-dashboard .ci-dashboard-content {
  margin-top: 4rem;
}
.ci-dashboard #ci-overview .kpis-metadata {
  text-align: center;
}
.ci-dashboard #graph-grid {
  margin-top: 4rem;
}
@media only screen and (max-width: 960px) {
  .ci-dashboard .pass-fail-col {
    margin-bottom: 4rem;
  }
}

.report-dashboard {
  margin: 0;
}
.report-dashboard #spinner-all, .report-dashboard #spinner-cis1, .report-dashboard #spinner-cis2, .report-dashboard #spinner-ntt {
  margin: 5rem;
}
.report-dashboard .critical-ci-text {
  color: #606f7d;
}
.report-dashboard .report-dashboard-content #customer-overview {
  margin-top: 4rem;
}
.report-dashboard .report-dashboard-content #platforms-overview, .report-dashboard .report-dashboard-content #most-critical-cis-overview {
  margin-bottom: 4rem;
}
.report-dashboard .report-dashboard-content .platforms-cis-count {
  text-align: center;
}
.report-dashboard .report-dashboard-content #spinner-most-critical-cis {
  position: relative;
  justify-content: center;
  align-items: center;
}

.kpis {
  margin-bottom: 1rem;
}
.kpis .kpi-label {
  text-align: right;
  margin-top: 7px;
}
@media only screen and (max-width: 960px) {
  .kpis .kpi-label {
    text-align: left;
    margin-bottom: 0.5rem;
  }
}
.kpis .body .kpi-pills {
  display: flex;
  margin-top: 0 !important;
}
.kpis .body .kpi-pills .item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  transition: transform 99ms ease-out;
  color: white;
  text-decoration: none;
  min-width: 16px;
}
.kpis .body .kpi-pills .item:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.kpis .body .kpi-pills .item:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.kpis .body .kpi-pills .item:hover {
  transform: scale(1.05);
}
.kpis .body .kpis-legend {
  display: flex;
  justify-content: center;
  padding: 1rem;
}
.kpis .body .kpis-legend .legend-item {
  margin-right: 20px;
  display: flex;
}
.kpis .body .kpis-legend .legend-item .item-color {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
  align-self: center;
}
.kpis .body .PASS {
  background-color: #1dbd45;
}
.kpis .body .FAIL, .kpis .body .CRITICAL {
  background-color: #ef4023;
}
.kpis .body .NO-ACCESS, .kpis .body .MEDIUM {
  background-color: #ffbd53;
}
.kpis .body .LOW {
  background-color: #0fc8f2;
}
.kpis .body .NO-SUPPORT {
  background-color: #2cd5b6;
}
.kpis .body .INFORMATIONAL {
  background-color: #6554C0C0;
}